import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { load as loadAccount } from './../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Loader
import Loader from '../../App/Loader';

// Slect
import Select from '../../../shared/components/form/Select'

 const ViewModal = ({type,openViewModal,closeViewModal}) =>  {
  
  const [rowData,setRowData] = useState({}); 
 

  // componentDidMount = () => {
  //   this.props.onRef(this);
  // };

  // componentWillUnmount() {
  //   this.props.onRef(null);
  // }

  const getRowData = (rowData) => {
    setRowData(rowData);
    if (type == 'Teams') {
    }
  };

  
    // const modalClass = classNames({
    //   'modal-dialog--colored': this.state.colored,
    //   'modal-dialog--header': this.state.header,
    // });
    return (
      <div style={{ display: 'flex' }}>
        <Modal isOpen={openViewModal}
          className={` modal-dialog--primary modal-dialog--header`}
        >
          <ModalHeader className="modal__header viewModalHeader" >
            {type} Details
            <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeViewModal} />
          </ModalHeader>
          <ModalBody>
            {rowData && rowData.length > 0 ?
              <div className="row form" >
                {rowData.map((item, i) => {
                  return (
                    item.value != "" || null ?
                      <div className="col-sm-6" key={i}>
                        <div className="row" style={{ margin: "auto" }}>
                          <div
                            className="col-sm-5"
                            style={{ textAlign: "left", padding: "5px 5px" }}>
                            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                              {item.label}
                            </span>
                          </div>

                          <div className="col-sm-7"
                            style={{ textAlign: "left", padding: "5px 0px" }}>
                            <span>{item.value}</span>
                          </div>
                        </div>
                      </div> : null
                  );
                })}
              </div>
              : null}
          </ModalBody>
   
        </Modal>

      </div >
    );

  }



export default ViewModal;
