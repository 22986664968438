/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter,Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import UserActivities from '../Cruds/UserActivities/index';
import UserActivitySummaries from '../Cruds/UserActivitySummaries/index';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Verification from '../Landing/LogIn/components/index.jsx';



const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
    <Routes>
      <Route path='/sampleform'
        element={<SampleForm />}
      ></Route>
<Route exact
       path="/employees"
      element={
              roles['Employees'] &&
                (roles['Employees'] === "Edit" || roles['Employees'] === "View")
                ? <Employees/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_employees"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'] === "Edit" || roles['Employees'] === "View")
              ? <Employees  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/userActivities"
      element={
              roles['User Activities'] &&
                (roles['User Activities'] === "Edit" || roles['User Activities'] === "View")
                ? <UserActivities/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_userActivities"
          element={
            roles['User Activities'] &&
              (roles['User Activities'] === "Edit" )
              ? <UserActivities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_userActivities/:id"
          element={
            roles['User Activities'] &&
              (roles['User Activities'] === "Edit" )
              ? <UserActivities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_userActivities/:id"
          element={
            roles['User Activities'] &&
              (roles['User Activities'] === "Edit" || roles['User Activities'] === "View")
              ? <UserActivities  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/userActivitySummaries"
      element={
              roles['User Activity Summaries'] &&
                (roles['User Activity Summaries'] === "Edit" || roles['User Activity Summaries'] === "View")
                ? <UserActivitySummaries/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_userActivitySummaries"
          element={
            roles['User Activity Summaries'] &&
              (roles['User Activity Summaries'] === "Edit" )
              ? <UserActivitySummaries/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_userActivitySummaries/:id"
          element={
            roles['User Activity Summaries'] &&
              (roles['User Activity Summaries'] === "Edit" )
              ? <UserActivitySummaries/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_userActivitySummaries/:id"
          element={
            roles['User Activity Summaries'] &&
              (roles['User Activity Summaries'] === "Edit" || roles['User Activity Summaries'] === "View")
              ? <UserActivitySummaries  />  
              : <ErrorNotFound/>
          }
        />

      {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

      {/* <Route path="/settings" element={Settings} /> */}
      {/* <Route path="/adminSettings" element={AdminSettings} /> */}
      <Route
        path="/adminSettings"
        element={
          config.displaySettings
            ? <AdminSettings />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/uploads"
        element={
          roles["Upload Histories"] &&
            (roles["Upload Histories"] === "Edit" || roles["Upload Histories"] === "View")
            ? <Uploads />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/activities"
        element={
          roles["Activities"] &&
            (roles["Activities"] === "Edit" || roles["Activities"] === "View")
            ? <Activities />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit" || roles["Roles"] === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit")
            ? <Roles  />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"] === "Edit" || roles["Roles"] === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/emailStatuses"
        element={
          roles["Email Statuses"] &&
            (roles["Email Statuses"] === "Edit" || roles["Email Statuses"] === "View")
            ? <EmailStatus />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit")
            ?<EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit")
            ?  <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
    </Routes >
    <Outlet />
    </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />
    
  </Routes>
  
);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/two_step_verification" element={<Verification />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />}  />
        
        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
